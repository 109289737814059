
import type { PropType } from 'vue'
import {
  defineComponent, ref
} from 'vue'
import ContactDetailsHeader from '@/components/pages/contacts/contactDetails/ContactDetailsHeader.vue'
import ContactDetailsMainActivities
  from '@/components/pages/contacts/contactDetails/ContactDetailsMainActivities.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { notify } from '@kyvg/vue3-notification'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'

export default defineComponent({
  components: {
    TmButton,
    ContactDetailsMainActivities,
    ContactDetailsHeader,
  },
  props: {
    sidebarVisible: {
      type: Boolean,
    },
    contact: {
      type: Object as PropType<ContactLine>,
      required: true,
    },
  },
  emits: ['update:sidebar-visible'],
  setup() {
    const noteValue = ref('')
    const isNoteVisible = ref(false)
    const noteClearAndHide = () => {
      isNoteVisible.value = false
      noteValue.value = ''
    }
    const noteSent = () => {
      notify({ text: 'Note successfully sent', type: 'success' })
      noteClearAndHide()
    }
    return {
      noteValue,
      isNoteVisible,
      noteSent,
      noteClearAndHide,
    }
  },
})
