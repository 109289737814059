
import { defineComponent, ref, computed } from 'vue'
import DetailsGridSidebar from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import DetailsSidebarHeader from '@/components/shared/templates/details/sidebar/DetailsSidebarHeader.vue'
import type { SidebarSectionType } from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import DetailsSidebarSearch from '@/components/shared/templates/details/sidebar/DetailsSidebarSearch.vue'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'
import { detailsSidebarActivities } from '@/definitions/tickets/data'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import {
  assigneeOptions, contactListsOptions, contactSegmentsOptions, contactsStatusOptions, organizationsOptions, tagsOptions
} from '@/definitions/_general/_data/optionsList'
import { useModes } from '@/compositions/modes'
import TmButton from '@/components/shared/TmButton.vue'
import type { ListChip } from '@/definitions/shared/types'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import { interactions, systemInfo } from '@/definitions/contacts/contactDetails/data'

export default defineComponent({
  components: {
    ActionButton,
    TmButton,
    TmSystemInfo,
    TmFieldsContainer,
    SearchNoResults,
    DetailsSidebarSearch,
    DetailsGridSidebar,
    DetailsSidebarHeader,
  },
  emits: ['close'],
  setup() {
    const { openModalEditContact } = useContactsOpenModal()
    const { isMdMax } = useBreakpoints()
    const { isEmptyMode } = useModes()
    const search = ref('')
    const showSearch = ref(false)
    const sidebarSections = computed<SidebarSectionType[]>(() => search.value.length > 2
      ? []
      : [
          {
            label: 'Contact info',
            expanded: true,
          },
          {
            label: 'Insights',
            expanded: true,
          },
        ])

    const hideSearch = () => {
      showSearch.value = false
      search.value = ''
    }

    const infoShowMore = ref(false)
    const insightsShowMore = ref(false)

    const fullName = ref('Jennifer Crowford')
    const whatsApp = ref(isEmptyMode.value ? '' : '(990) 338-9983')
    const phones = ref(isEmptyMode.value
      ? []
      : [{ phone: '(990) 338-9983', type: 'Work' }])
    const emails = ref(isEmptyMode.value
      ? []
      : [{ email: 'j.crowford@mail.com', type: 'Work' }])
    const jobTitle = ref(isEmptyMode.value ? '' : 'Office manager')
    const socialMedia = ref(isEmptyMode.value ? [] : ['facebook.com/textmagic', 'twitter.com/textmagic', 'linkedin.com/textmagic'])
    const organization = ref(isEmptyMode.value ? null : organizationsOptions[0])

    const statusValue = ref(contactsStatusOptions[0])
    const statusOptions = contactsStatusOptions

    const ownerValue = ref(
      isEmptyMode.value
        ? { fullName: 'Unassigned', avatarColor: 'gray' }
        : assigneeOptions[3])

    const listsValue = ref(isEmptyMode.value
      ? []
      : [contactListsOptions[0]])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])

    const segmentsOptions = ref(isEmptyMode.value ? [] : [...contactSegmentsOptions])
    const segmentsValue = ref(isEmptyMode.value ? [] : [contactSegmentsOptions[0]])

    const tagsValue = ref(isEmptyMode.value ? [] : tagsOptions.slice(0, 2))

    const commonProps = computed(() => {
      return {
        leftLabel: !isMdMax.value,
        search: search.value,
        inputContainerClass: isMdMax.value ? 'mx-n2' : '',
      }
    })

    return {
      hideSearch,
      detailsSidebarActivities,
      showSearch,
      sidebarSections,
      isMdMax,
      search,
      commonProps,
      fullName,
      phones,
      whatsApp,
      emails,
      jobTitle,
      socialMedia,
      organization,
      organizationsOptions,
      statusValue,
      statusOptions,
      ownerValue,
      assigneeOptions,
      listsValue,
      listsOptions,
      segmentsValue,
      segmentsOptions,
      tagsValue,
      tagsOptions,
      interactions,
      systemInfo,
      infoShowMore,
      insightsShowMore,
      openModalEditContact,
    }
  },
})
