import { ref } from 'vue'

export const activities = ref([
  {
    name: 'activities',
    label: 'Activities',
    to: { name: 'base.contacts.contactDetails.activities' },
    counter: 27,
  },
  {
    name: 'notes',
    label: 'Notes',
    to: { name: 'base.contacts.contactDetails.notes' },
    counter: 2,
  },
  {
    name: 'chats',
    label: 'Chats',
    to: { name: 'base.contacts.contactDetails.chats' },
    counter: 10,
  },
  {
    name: 'calls',
    label: 'Calls',
    to: { name: 'base.contacts.contactDetails.calls' },
    counter: 5,
  },
  {
    name: 'emails',
    label: 'Emails',
    to: { name: 'base.contacts.contactDetails.emails' },
    counter: 9,
  },
  {
    name: 'files',
    label: 'Files',
    to: { name: 'base.contacts.contactDetails.files' },
    counter: 8,
  },
])

export const interactions = [
  {
    label: 'Interactions',
    text: '32',
  },
  {
    label: 'Last interaction',
    text: '11 Jul, 3:14 am',
  },
  {
    label: 'Last outbound',
    text: '11 Jul, 3:14 am',
  },
  {
    label: 'Last inbound',
    text: '11 Jul, 3:14 am',
  },
]

export const systemInfo = [
  {
    label: 'ID',
    text: '3225343',
  },
  {
    label: 'Created',
    text: '11 Jul 2021, 3:14 am',
  },
  {
    label: 'Updated',
    text: 'Yesterday, 11:33 pm',
  },
]
