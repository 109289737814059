import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f44513a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "pa-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_button = _resolveComponent("action-button")!
  const _component_details_sidebar_header = _resolveComponent("details-sidebar-header")!
  const _component_details_sidebar_search = _resolveComponent("details-sidebar-search")!
  const _component_tm_fields_container = _resolveComponent("tm-fields-container")!
  const _component_tm_system_info = _resolveComponent("tm-system-info")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_search_no_results = _resolveComponent("search-no-results")!
  const _component_details_grid_sidebar = _resolveComponent("details-grid-sidebar")!

  return (_openBlock(), _createBlock(_component_details_grid_sidebar, {
    "sidebar-sections": _ctx.sidebarSections,
    "no-padding": "",
    "small-header": _ctx.isMdMax
  }, {
    "sidebar-header": _withCtx(() => [
      (!_ctx.showSearch)
        ? (_openBlock(), _createBlock(_component_details_sidebar_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_action_button, {
                icon: "edit",
                text: "Edit",
                class: "neutral--text",
                onClick: _ctx.openModalEditContact
              }, null, 8, ["onClick"]),
              _createVNode(_component_action_button, {
                icon: "search",
                text: "Search",
                class: "neutral--text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSearch = true))
              }),
              _createVNode(_component_action_button, {
                icon: "close",
                text: "Close sidebar",
                class: "neutral--text",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_details_sidebar_search, {
            key: 1,
            search: _ctx.search,
            "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
            onHideSearch: _ctx.hideSearch
          }, null, 8, ["search", "onHideSearch"]))
    ]),
    "sidebar-section-contact-info": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.fullName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fullName) = $event)),
          label: "Full name",
          type: "fullName"
        }, _ctx.commonProps), null, 16, ["modelValue"]),
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.phones,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.phones) = $event)),
          label: "Phone",
          type: "phone"
        }, _ctx.commonProps), null, 16, ["modelValue"]),
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.whatsApp,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.whatsApp) = $event)),
          label: "Whatsapp",
          type: "whatsapp"
        }, _ctx.commonProps), null, 16, ["modelValue"]),
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.emails,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.emails) = $event)),
          label: "Email",
          type: "email"
        }, _ctx.commonProps), null, 16, ["modelValue"]),
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.jobTitle,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.jobTitle) = $event)),
          label: "Job title",
          type: "text",
          placeholder: "Add job title"
        }, _ctx.commonProps), null, 16, ["modelValue"]),
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.socialMedia,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.socialMedia) = $event)),
          label: "Social media",
          type: "socialMedia"
        }, _ctx.commonProps), null, 16, ["modelValue"]),
        _createVNode(_component_tm_fields_container, _mergeProps({
          modelValue: _ctx.organization,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.organization) = $event)),
          label: "Organization",
          type: "selectOrganization",
          "show-link": !!_ctx.organization?.domain,
          options: _ctx.organizationsOptions
        }, _ctx.commonProps), null, 16, ["modelValue", "show-link", "options"]),
        (_ctx.infoShowMore)
          ? (_openBlock(), _createBlock(_component_tm_system_info, {
              key: 0,
              items: _ctx.systemInfo
            }, null, 8, ["items"]))
          : _createCommentVNode("", true),
        _createVNode(_component_tm_button, {
          "text-link": "",
          class: "contact-details-sidebar__show-more primary--text",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.infoShowMore = !_ctx.infoShowMore))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.infoShowMore ? "Show less" : "Show more"), 1)
          ]),
          _: 1
        })
      ])
    ]),
    "sidebar-section-insights": _withCtx(() => [
      _createVNode(_component_tm_fields_container, _mergeProps({
        modelValue: _ctx.statusValue,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.statusValue) = $event)),
        label: "Contact type",
        type: "selectStatus",
        options: _ctx.statusOptions
      }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, _mergeProps({
        modelValue: _ctx.ownerValue,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ownerValue) = $event)),
        label: "Contact owner",
        type: "assignee",
        size: "small",
        "avatar-size": "xxSmall",
        "empty-placeholder": "No owner",
        options: _ctx.assigneeOptions
      }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, _mergeProps({
        modelValue: _ctx.listsValue,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.listsValue) = $event)),
        label: "Lists",
        type: "tagsList",
        "option-search": "label",
        "sidebar-field-margins": "",
        options: _ctx.listsOptions
      }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, _mergeProps({
        modelValue: _ctx.segmentsValue,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.segmentsValue) = $event)),
        label: "Segments",
        type: "tagsList",
        "option-search": "label",
        placeholder: "Add segments",
        "sidebar-field-margins": "",
        options: _ctx.segmentsOptions
      }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, _mergeProps({
        modelValue: _ctx.tagsValue,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.tagsValue) = $event)),
        label: "Tags",
        type: "tags",
        "one-line": "",
        "hide-input": "",
        options: _ctx.tagsOptions,
        placeholder: "Add tags"
      }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
      (_ctx.insightsShowMore)
        ? (_openBlock(), _createBlock(_component_tm_system_info, {
            key: 0,
            items: _ctx.interactions
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_button, {
        "text-link": "",
        class: "contact-details-sidebar__show-more primary--text",
        onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.insightsShowMore = !_ctx.insightsShowMore))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.insightsShowMore ? "Show less" : "Show more"), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.search.length > 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_search_no_results, {
              bordered: "",
              size: "small",
              title: "No search results found",
              text: "Please try a different keyword"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["sidebar-sections", "small-header"]))
}