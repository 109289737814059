
import {
  defineComponent,
  ref
} from 'vue'
import ContactDetailsSidebar from '@/components/pages/contacts/contactDetails/ContactsDetailsSidebar.vue'
import ContactDetailsMain from '@/components/pages/contacts/contactDetails/ContactDetailsMain.vue'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import DetailsTemplate from '@/components/shared/templates/details/DetailsTemplate.vue'

export default defineComponent({
  components: {
    DetailsTemplate,
    ContactDetailsMain,
    ContactDetailsSidebar,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Contacts', url: { name: 'base.contacts.allContacts' } },
      { label: 'Ralph Edwards' },
    ]

    const contact = ref<ContactLine>({
      person: {
        firstName: 'Ralph',
        lastName: 'Edwards',
        fullName: 'Ralph Edwards',
        avatarColor: 'crimson',
      },
      infos: ['(776) 998-2246', 'j.crowford@mail.com'],
      type: {
        name: 'lead',
        color: 'green',
        outline: true,
      },
      company: 'Kuhn Group',
      country: {
        name: 'United States',
        id: 'us',
      },
    })

    return {
      breadcrumbs,
      contact,
    }
  },
})
